<template>
  <div data-vue-component-name="Events">
    <img
      src="backgrounds/events.jpg"
      alt="events background"
    >

    <transition name="fade" mode="out-in" appear>
      <EventsMultipleView
        v-if="isNull(activeItem)"
        @set-active-item="toggleActiveItem"
      />

      <EventsSingleView
        v-else
        :item="activeItem"
        @on-close="toggleActiveItem(null)"
      />
    </transition>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { metaController } from '@/modules';
import { isNull } from '@xsys2/functions';

metaController({
  title: 'Events Page',
  link: [
    {
      rel: 'preload',
      href: 'backgrounds/events.jpg',
      as: 'image',
    },
  ],
});

const store = useStore();
store.dispatch('events/getData');

const activeItem = ref(null);
const toggleActiveItem = item => (activeItem.value = item);
</script>

<style scoped lang="scss">
[data-vue-component-name="Events"] {
  width: 100%;
  height: 100%;
  color: #fff;

  img {
    @include absolute-full;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
    overflow: hidden;
  }

  [data-vue-component-name="EventsMultipleView"],
  [data-vue-component-name="EventsSingleView"], {
    height: 100%;
    width: 100%;
  }
}
</style>
